import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React from "react"
import styles from "./contactIcons.module.css";

export enum ContactIconsVariant {
    NAV,
    HOMEPAGE
}

export const ContactIcons = (props: { variant: ContactIconsVariant }) => {
    return <div className={props.variant === ContactIconsVariant.NAV ? styles.contactLinksNav : styles.contactLinksHomepage}>
        <Link to="/contact"><FontAwesomeIcon icon={faEnvelope} /></Link>
        <a href="https://github.com/kpkozak" target="_blank"><FontAwesomeIcon icon={faGithub} /></a>
        <a href="https://pl.linkedin.com/in/karol-k-83a301b2" target="_blank"><FontAwesomeIcon icon={faLinkedinIn} /></a>
    </div>
}