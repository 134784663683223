import React from "react"
import { Navigation } from "./navigation/navigation";
import styles from "./layout.module.css";

const Layout = ({ location, title, children }) => {
  
  return (
    <div className={styles.pageWrapper}>
      <aside className={styles.sidebar}>
        <Navigation title={title} location={location} />
      </aside>
      <main className={styles.main}>{children}</main>
    </div>
  )
}

export default Layout;
