import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import styles from "./bio.module.css";
import { ContactIcons, ContactIconsVariant } from "../contactIcons/contactIcons";

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(width: 80, height: 80, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className={styles.bio}>
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className={styles.avatar}
        />
      )}
      <p className={styles.author}>
        <strong>{author.name}</strong>        
      </p>
      {author?.summary && <p className={styles.summary}>
        {author?.summary}
      </p>}
      <ContactIcons variant={ContactIconsVariant.NAV} />
    </div>
  )
}

export default Bio
