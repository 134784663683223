
export function scrolledAtLeastTo(element: HTMLElement, interationMargin: number = 0): boolean {
  const elementHeight = element.offsetHeight;
  const windowHeight = window.innerHeight;
  const elementTop = element.offsetTop;

  let scrollTop = window.pageYOffset;

  return (scrollTop + windowHeight - interationMargin > elementTop) ;
}

export function isInViewport(element: HTMLElement, interactionMargin: number = 0) {
  const elementRect = element.getBoundingClientRect();
  const windowHeight = window.innerHeight;

  return elementRect.top > interactionMargin
    && elementRect.bottom < windowHeight-interactionMargin;
}
