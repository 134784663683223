import { Link } from "gatsby";
import React from "react";
import ReactDOM from "react-dom";
import { scrolledAtLeastTo } from "../../utils/viewportUtils";
import Bio from "./bio";
import styles from "./navigation.module.css";
import { ContactIcons, ContactIconsVariant } from "../contactIcons/contactIcons";
import { NewsletterForm } from "../newsletterForm/newsletterForm";
import Modal from "react-modal/lib/components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons'

declare var __PATH_PREFIX__;

export class Navigation extends React.Component<{ title: string, location: string }, {isNewsletterVisible: boolean}> {
  private sections: NodeListOf<HTMLElement>;
  private navRef: React.RefObject<HTMLElement>;

  constructor(props: any) {
    super(props);

    this.state = {
      isNewsletterVisible: false
    };

    this.navRef = React.createRef<HTMLElement>();
    this.handleHighlights = this.handleHighlights.bind(this);
    this.isRootPath = this.isRootPath.bind(this);
    this.showNewsletterModal = this.showNewsletterModal.bind(this);
    this.hideNewsletterModal = this.hideNewsletterModal.bind(this);
  }

  render() {
    let { title, location } = this.props;
    const isRootPath = this.isRootPath(location);
    const homepageLinks = ["Home", "About"].map(sectionId => {
      return isRootPath
        ? <a key={sectionId}
             data-target={sectionId}
             onClick={() => document.getElementById(sectionId)?.scrollIntoView(true)}>{sectionId}</a>
        : <Link to={`/#${sectionId}`}>{sectionId}</Link>
    })
    return <>
      <div className={styles.wrapper}>
      <Bio />
      <nav className={styles.navigation} ref={this.navRef}>
        {homepageLinks}
        <Link to="/blog" 
          className={location.pathname.indexOf("blog") > -1
          ? styles.active
          :""}>Blog</Link>
        <button onClick={this.showNewsletterModal}>Subscribe</button>
      </nav>

      <footer>
        <div className={styles.copyright}>
          Copyright © {new Date().getFullYear()}<br />CC BY 4.0
        </div>
      </footer>
      {this.state.isNewsletterVisible && this.renderNewsletter()}
      
    </div>
    </>
  }

  componentDidMount() {
    if (this.isRootPath(this.props.location)) {
      
      window.addEventListener("scroll", this.handleHighlights)

      const hash = document.location.hash;
      if (hash !== "") {
        window.history.replaceState({}, null, "#");
        document.getElementById(hash.substr(1))?.scrollIntoView(true);
      }

      this.sections = document.querySelectorAll("section[id]");
      this.handleHighlights();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleHighlights)
  }

  renderNewsletter() {
    return <Modal
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
      isOpen={this.state.isNewsletterVisible}
      onRequestClose={this.hideNewsletterModal}
      contentLabel="Newsletter"
    >
      <button onClick={this.hideNewsletterModal} className={styles.modalCloseButton}>
        <FontAwesomeIcon icon={faTimes}/>
      </button>
      <NewsletterForm />
      </Modal>
  }

  handleHighlights() {
    let activeSectionId = "";
    this.sections.forEach(current => {
      const inViewport = scrolledAtLeastTo(current, window.innerHeight/2);
      if (inViewport) {
        activeSectionId = current.getAttribute("id");
      }
    });

    const className = styles.active;
    for (const link of this.navRef.current.children) {
      const shouldBeActive = Navigation.isLinkTo(link, activeSectionId);
      if (shouldBeActive && !link.classList.contains(className)) {
        link.classList.add(className);
      }
      else if (!shouldBeActive && link.classList.contains(className)) {
        link.classList.remove(className);
      }
    }
  }

  isRootPath(location: any): boolean {
    const rootPath = `${__PATH_PREFIX__}/`
    return location.pathname === rootPath;
  }

  showNewsletterModal() {
    this.setState({isNewsletterVisible: true});
  }

  hideNewsletterModal() {
    this.setState({isNewsletterVisible: false});
  }

  static isLinkTo(link: Element, activeSectionId: string): boolean {
    return link.getAttribute("data-target") === activeSectionId;
  }
}

