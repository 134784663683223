import React from "react";
import styles from "./newsletterForm.module.css";

export const NewsletterForm = () => {
    return <div id="mc_embed_signup" className={styles.form}>
        <p className={styles.subscribe}>Hello! Drop your e-mail to receive notifications about new articles.</p>
        <form action="https://gmail.us6.list-manage.com/subscribe/post?u=4e9ffb04e4218c7351eb7084e&amp;id=635a778a21" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
            <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                    <input type="email" name="EMAIL" required className="required email" id="mce-EMAIL" placeholder="Your e-mail"/>
                    <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">Submit</button>
                </div>
                
                <div id="mce-responses" className="clear">
                    <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                    <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                </div>
                
                <p className={styles.consent}>By submitting this form, you agree to processing of your personal data in order to send notifications about new content published on kkozak.pl website to the e-mail address provided. You can unsubscribe at any time.</p>
                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true"><input type="text" name="b_4e9ffb04e4218c7351eb7084e_635a778a21" tabIndex={-1} value="" />
                </div>    
            </div>
        </form>
    </div>
}